import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import BootstrapVueNext from "bootstrap-vue-next";

import VueFeather from "vue-feather";
import VueSelect from "vue-select";
import bottomNavigationVue from "bottom-navigation-vue";
import VueApexCharts from "vue3-apexcharts";

import "bottom-navigation-vue/dist/style.css";
import "@/assets/scss/config/default/app.scss";
import "leaflet/dist/leaflet.css";
import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./registerServiceWorker";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVueNext)
  .use(i18n)
  .use(bottomNavigationVue)
  .use(VueApexCharts)
  .component(VueFeather.type, VueFeather)
  .component("v-select", VueSelect);

// app.config.globalProperties.$messaging = firebaseMessaging;

app.mount("#app");
